import "styles/pages/page-404.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import { Link } from "gatsby";
import SubpageHeader from "components/SubpageHeader";

const NotFoundPage = () => {
  return (
    <Layout headerColor="#FFCE2E">
      <Seo title="404" />
      <SubpageHeader image={require("assets/images/404-bg-main.jpg").default} />
      <section className="not-found">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 text-center">
              <img
                src={require("assets/images/404-img-1.png").default}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-xl-4 offset-xl-1 text-center">
              <div className="not-found__error">404</div>
              <div className="not-found__text">
                <p>
                  <strong>
                    Strona, której szukasz najprawdopodobniej wygrzewa się na
                    piasku jednej z malowniczych wysp Tajlandii.
                  </strong>
                </p>
              </div>
              <Link to="/" className="button">
                Strona Główna
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
